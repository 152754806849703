<template>
  <div class="c-login-coupon">
      <van-overlay :show="show" @click="onCloseDialog">
        <div class="dialog">
          <div class="coupon">
            <div class="home-cover"></div>
            <div class="date">有效期至<i>{{couponDate}}</i></div>
            <van-image :src="require('../assets/img/home-coupon.png')" :width="210 / 37.5 + 'rem'" :height="316 / 37.5 + 'rem'" fit="cover" loading-icon="" error-icon=""></van-image>
          </div>
          <c-image iUrl="icon-close_l" iClass="btn" iWidth="36" iHeight="36" ></c-image>
        </div>
      </van-overlay>
  </div>
</template>

<script>
import { Image, Overlay } from "vant";
import CImage from "./CImage";
import { getAppointDateFromCurrent } from '../lib/util'
import { mapState } from 'vuex'
export default {
    name: "c-login-coupon",
    components: {
        [Image.name]: Image,
        [Overlay.name]: Overlay,
        [CImage.name]: CImage,
    },
    data() {
        return {
            couponDate: "",
            show: false,
        }
    },
    computed: {
        ...mapState({
            userData: (state) => state.me.userData,
        })
    },
    methods: {
        onCloseDialog() {
            this.show = false;
            if (this.$route.name == 'home' || this.$route.name == 'detail') {
                let _inviteCode = this.$cookies.get('invite_code')
                this.$router.replace({ path: this.$router.path, query: { inviteCode: _inviteCode } })
            }
            else if (this.$route.name == 'products') this.$router.replace({ path: this.$router.path, query: { keywords: this.$route.query.keywords, option: this.$route.query.option } })
            else this.$router.replace({ name: this.$route.name })
        }
    },
    created() {
        this.couponDate = getAppointDateFromCurrent(3)
    }
}
</script>

<style>
#me .c-login-coupon .van-overlay{background: rgba(0,0,0,.6) !important;}
.c-login-coupon .btn{margin:24px auto 0}
.c-login-coupon .date{position: absolute; width:100%; left: 0; top: 227px; z-index: 1; font-size: 12px; color: #b3b3b3; text-align: center;}
.c-login-coupon .date i{font-style: normal; color: #F85659; margin-left: 10px;}
</style>