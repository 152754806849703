<template>
  <div class="products">

    <div class="products-content" :class="{on:pageLoaded}">
        <!-- list -->
        <template v-if="list.length > 0">
            <van-list v-model="loading" :finished="finished" loading-text=" " :immediate-check="false" finished-text="我是有底线的，试试其它的关键词吧"  @load="onLoadList" :offset="100">
                <div class="list">
                    <c-waterfall :list="list" :winWidth="winWidth"></c-waterfall>
                </div>
            </van-list>
        </template>

        <!-- empty -->
      <template v-else>
        <div class="product-empty">
          <c-image iUrl="icon-empty" iClass="icon" iWidth="120" iHeight="136" ></c-image>
            <template v-if="keyWords == ''">
              <h3>没有找到相关的作品</h3>
            </template>
            <template v-else>
              <h3>没有找到与<i>“{{keyWords}}”</i>相关的作品</h3>
              <p>试试搜索别的关键词吧</p>
            </template>
        </div>
      </template>
    </div>

    <c-login-coupon ref="cLoginCoupon"></c-login-coupon>
    <c-loading :showLoading="!pageLoaded"></c-loading>
  </div>
</template>

<script>
import { Image, List } from "vant";
import CImage from "../../components/CImage.vue";
import CWaterfall from "../../components/CWaterfall.vue";
import { mapState, mapActions, mapMutations } from "vuex";
import { toastLoading, closeToast } from "../../lib/util";
import CLoginCoupon from '../../components/CLoginCoupon';
import { loginCoupon } from '../../lib/mix/login-coupon';
import { loginThird } from "../../lib/mix/login-third";
import CLoading from "../../components/CLoading.vue"
export default {
    components: {
        [Image.name]: Image,
        [List.name]: List,
        [CImage.name]: CImage,
        [CWaterfall.name]: CWaterfall,
        [CLoginCoupon.name]: CLoginCoupon,
        [CLoading.name]:CLoading,
    },
    mixins: [loginCoupon, loginThird],
    data() {
        return {
            pageLoaded: false, //页面加载完成
            loading: false, //分页加载
            finished: false, //分页全部加载完成
            winWidth: window.innerWidth,
            winHeight: parseInt(window.innerHeight),

            list: [], //作品列表

            option_most: 0, //1为热门推荐，2为最新上传
            page: 1, //页数

            time: null,

            key: "", //搜索关键词
        };
    },
    computed: {
        ...mapState({
            routeName: (state) => state.routeName, //当前页面名称
            fromRoute: (state) => state.fromRouteName, //上一页页面名称
            searchList: (state) => state.search.searchData.list, //接口返回当前需加载作品列表
            totalPages: (state) => state.search.searchData.total_pages, //总页数
            reloadSearch: (state) => state.search.reloadSearch, //判断是否需要刷新
            keyWords: (state) => state.search.keyWords, //本页面搜索，查询搜索词
            os: (state) => state.os,
        }),
    },
    watch: {
        searchList(val) {
            if (val.length > 0) {
                //分页加载作品列表
                this.list = this.list.concat(val);
                this.page++;
            } else {
                if (this.page > 1) {
                    //分页加载作品如果返回空数组强制停止分页功能
                    this.finished = true;
                } else {
                    this.list = []; //无搜索作品清空列表
                }
            }
        },
        reloadSearch(val) {
            if (val) {
                //当前页面搜索作品
                this.key = this.keyWords;

                this.$router.replace({
                    name: 'products',
                    params: { keywords: this.key },
                    query: { keywords: this.key }
                }).then(() => {
                    this.loadList();
                    this.setReloadSearch(false);
                    this.setHistoryKeywords(this.key);

                    this.changeTitle();
                    if (this.os.safari) document.body.scrollTop = document.documentElement.scrollTop = 0;
                })


            }
        },
    },
    methods: {
        ...mapActions({
            requestSearch: "search/requestSearch",
            requestAdWaterfall: "ad/requestAdWaterfall",
        }),
        ...mapMutations({
            setKeywords: "search/SET_KEY_WORDS",
            setReloadSearch: "search/SET_RELOAD_SEARCH",
            setHistoryKeywords: "search/SET_HISTORY_KEY_WORDS",
        }),
        onLoadList() {
            if (this.pageLoaded && this.list.length > 0) {
                this.time = setTimeout(() => {
                    clearTimeout(this.time);
                    if (this.loading) {
                        if (this.page > this.totalPages) this.finished = true;
                        else {
                            this.loadSearch();
                        }
                    }
                }, 100);
            }
        },

        loadSearch() {
            return new Promise((resolve) => {
                let _params = {};

                _params.keywords = this.key;
                _params.p = this.page;

                if (this.option_most != 0) _params.option_most = this.option_most;

                this.requestSearch(_params).then(() => {
                    closeToast();

                    this.time = setTimeout(() => {
                        clearTimeout(this.time);
                        this.loading = false;
                    }, 300);

                    resolve();
                });
            });
        },
        loadList() {
            this.pageLoaded = false;
            this.finished = false;

            this.list = [];
            this.page = 1;

            this.option_most = this.$route.query.option || 0;
            sessionStorage.setItem('option', this.option_most)

            // toastLoading();
            this.loadSearch().then(() => {
                this.pageLoaded = true;
            });
        },
        changeTitle() {     //改变头部title
            if (this.key == 'more_works') {
                let _option = this.$route.query.option
                if (_option == 1) document.title = `热门推荐 - 享设计`
                else if (_option == 2) document.title = `最新上传 - 享设计`
            } else document.title = `${this.key}作品专辑 - 享设计`
        }
    },
    created() { },
    activated() {
        this.key = this.$route.query.keywords || "more_works";

        this.requestAdWaterfall();

        if (this.fromRoute == "home" || this.fromRoute == "detail" || this.fromRoute == "homepage" || this.fromRoute == "collect" || this.fromRoute == "invitation" || this.fromRoute == "login" || this.fromRoute == "404") {
            if (this.$route.params.keywords) {
                //上一路径页面带搜索词，重新加载页面
                this.setKeywords(this.key == "more_works" ? "" : this.key);
                //记录历史搜索关键词
                this.setHistoryKeywords(this.key == "more_works" ? "" : this.key);
                this.loadList();
            } else {
                //上一路径页面带搜索词，重新加载
                if (this.list.length > 0) {
                    //上一路径是页面不带搜索词，不重新加载
                    this.pageLoaded = true;
                    this.key = this.keyWords || "more_works";
                } else {
                    //如果list为空默认加载数据
                    this.setKeywords(this.key == "more_works" ? "" : this.key);
                    this.setHistoryKeywords(
                        this.key == "more_works" ? "" : this.key
                    );
                    this.loadList();
                }
            }
        } else {
            let _wd = this.$route.query.wd;     //如果url有wd参数，获取并搜索
            if (_wd) this.key = _wd;
            this.setKeywords(this.key == "more_works" ? "" : this.key);
            this.setHistoryKeywords(this.key == "more_works" ? "" : this.key);
            this.loadList();
        }

        this.changeTitle();
    },
    deactivated() {
        this.setKeywords("");
    },
    destroyed() { },
};
</script>

<style>
.product-cover{width:100%;height:100%;position:absolute;left:0;top:0;z-index:100}
.products-content{padding-top:59px;height:100vh;opacity: 0;}
.products-content.on{opacity: 1;}
.product-empty .icon{margin:40px auto}
.product-empty h3{text-align:center;font-size:16px;color:#2e2e30; font-weight: normal;}
.product-empty h3 i{font-style:normal;color:#f85659}
.product-empty p{text-align:center;font-size:14px;color:#b3b3b3;margin-top:8px}
.products-content .list{padding:0 12px}
</style>