<template>
  <div class="c-loading" @touchmove.prevent v-show="showLoading">
      <van-loading color="#f85659" size="24"/>
  </div>
</template>

<script>
import { Loading } from 'vant'
export default {
    name:'c-loading',
    components: {
        [Loading.name]: Loading
    },
    props:{
        showLoading:{
            type:Boolean,
            default:false,
            required:true
        }
    }
}
</script>

<style>
.c-loading{width:100%; height: 100%; position: fixed; left: 0; top: 0; z-index: 999; display: flex; justify-content: center; align-items: center;}
</style>