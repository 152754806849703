import { mapActions } from "vuex";


export const loginThird = {
    data() {
        return {
            baseUrl: 'https://m.design006.com'
        }
    },
    methods: {
        ...mapActions({
            requestGetAuth: "user/requestGetAuth",
        })
    },
    created() {

        if (this.$route.query.secret && !this.$cookies.isKey('user_id')) { //当前页面重定向，去掉secret参数(已注册过的用户登录)

            this.requestGetAuth({ secret: this.$route.query.secret }).then(() => {
                if (this.$route.name == 'home' || this.$route.name == 'detail') {
                    let _inviteCode=this.$cookies.get('invite_code')
                    this.$router.replace({ path: this.$router.path, query: { inviteCode: _inviteCode } })
                }
            })

        }


    },
}