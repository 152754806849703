<template>
  <div class="img" :style="{width: (170 / 37.5) + 'rem',height: (299/ 37.5) + 'rem'}" v-if="showPoster">
      <div class="c-btn-close" @click="onClosePoster">
        <c-image iUrl="icon-poster-close" iWidth="20" iHeight="20"></c-image>
      </div>
      <a :href="projectsAd.image_link" target="_blank" class="c-poster-products">
        <van-image :src="projectsAd.object_name" :lazy-load="false" width="100%" fit="cover" loading-icon="" error-icon=""></van-image>
      </a>
  </div>
</template>

<script>
import { Image } from 'vant';
import CImage from "./CImage.vue";
import { mapState, mapActions } from "vuex";
export default {
    name: 'c-poster-products',
    components: {
        [Image.name]: Image,
        [CImage.name]: CImage,
    },
    data() {
        return {
            showPoster: false
        }
    },
    computed: {
        ...mapState({
            routeName: (state) => state.routeName,
            projectsAd: (state) => state.ad.projectsAd
        })
    },
    methods: {
        onClosePoster() {
            this.showPoster = false;
            this.$cookies.set('freeze_poster_products', true, this.getCookiesTime())
        },
        getCookiesTime() {
            let _timeZero = new Date(new Date(new Date().toLocaleDateString()).getTime() + 1000 * 60 * 60 * 24);
            let _timeCurrent = new Date()
            let _disTime = Math.floor((_timeZero - _timeCurrent) / 1000);
            return _disTime;
        }
    },
    created() {
        this.getCookiesTime();
        if (!this.$cookies.isKey('freeze_poster_products') && this.routeName == 'products') {
            if (this.projectsAd.object_name && this.projectsAd.image_link) {
                this.showPoster = true;
            }
        }
    },
    activated() {
        if (!this.$cookies.isKey('freeze_poster_products') && this.routeName == 'products') {
            if (this.projectsAd.object_name && this.projectsAd.image_link) {
                this.showPoster = true;
            }
        }
    }
}
</script>

<style>
.c-poster-products img{width: 100%; vertical-align: top; height: auto;}
.c-btn-close{width: 20px; height: 20px; position: absolute; right: 4px; top: 4px; z-index: 1; padding:4px; box-sizing: content-box;}
</style>